import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../components/layout";

import InstagramFeed from "../../components/InstagramFeed";
import HeroHeader from "../../components/HeroHeader";
import Seo from "../../components/Seo";
import StepsToStart from "../../sections/StepsToStart";
import FAQs from "../../sections/FAQs";

const stepsToStartId = "steps";
const freeTrialId = "free-trial";
const faqsId = "faqs";

const heroHeaderQuery = graphql`
    query {
        image: file(relativePath: { eq: "getting-started-banner.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                )
            }
        }
    }
`;

export default function FAQPage() {
    const headerImage = useStaticQuery(heroHeaderQuery);
    return (
        <Layout>
            <Seo
                title="Frequently Asked Questions"
                meta_title="Frequently Asked Questions | FEAT Fitness"
                meta_desc="Still have some burning questions? Read more to see what most people frequently ask about FEAT Fitness, and outdoor group training."
                slug="faqs"
            />
            <main>
                <div>
                    {/* Hero card */}
                    <HeroHeader
                        title={"Frequently Asked Questions"}
                        background_image={headerImage?.image}
                        options={[
                            { title: "FAQs", to: `#${faqsId}` },
                            {
                                title: "First day of Training?",
                                to: `#${stepsToStartId}`,
                            },
                            {
                                title: "Free Trial",
                                to: `#${freeTrialId}`,
                                highlighted: true,
                            },
                        ]}
                        hideShading
                    />
                    {/* End hero */}

                    <FAQs id={faqsId} />

                    {/* Training with FEAT Fitness */}
                    <StepsToStart
                        id={stepsToStartId}
                        className="bg-feat-lightgray-400"
                    />
                    {/* END Training with FEAT Fitness */}

                    {/* Instagram Feed */}
                    <InstagramFeed />
                    {/* End Instagram Feed */}
                </div>
            </main>
        </Layout>
    );
}
